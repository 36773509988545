const questions = [
  {
    question: 'O que é Viuzz TV?',
    answer:
      'Uma plataforma de transmissão online, com os melhores conteúdos de entretenimento e produções exclusivas, conectando você ao seus artistas preferidos.\n\nConfira todos os projetos dos melhores influenciadores digitais, além de interagir e colaborar com o desenvolvimento profissional de cada um.\n\nA novidade chega primeiro aqui.',
  },
  {
    question: 'Como me cadastrar?',
    answer:
      'Assine a VIUZZ através do seu smartphone, tablet ou notebook, escolha o plano ideal para você e seja parte de um universo exclusivo de conteúdo dos seus artistas preferidos por um preço bem acessível e acesso ilimitado.',
  },
  {
    question: 'Onde posso assistir?',
    answer:
      'Assista instantaneamente em smartphones, tablets e computadores, direto no viuzz.com.br.',
  },
  {
    question: 'O que é Super Fã?',
    answer:
      'Um espaço que chegará em breve para unir você ao seu digital influencer favorito, onde você poderá interagir, conferir conteúdos exclusivos feito especialmente para você e colaborar com os futuros projetos.',
  },
  {
    question: 'Quais as formas de pagamento?',
    answer:
      'A cobrança é feita através de cartão de crédito (Visa e MasterCard). Os planos variam entre:\n\nMensal\nSemestral (em 6x com 15% de desconto)\nAnual (em 12x com 20% de desconto)\n\nVocê pode experimentar a plataforma gratuitamente por 7 dias e cancelar a sua assinatura a qualquer momento, sem custos.',
  },
]

export default questions
