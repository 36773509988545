import * as S from './style';
import { Grid } from '@mui/material';
import discover1 from '../../images/discover-icon-1.svg';
import discover2 from '../../images/discover-icon-2.svg';
import discover3 from '../../images/discover-icon-3.svg';

const Discover: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Title>Descubra Viuzz</S.Title>
      <Grid container>
        <S.Grid item xs={12} sm={6} lg={4}>
          <S.Icon src={discover1} />
          <S.Content>Assista de qualquer lugar </S.Content>
          <S.Description>
            Assista quando quiser, onde quiser e onde estiver.
          </S.Description>
        </S.Grid>
        <S.Grid item xs={12} sm={6} lg={4}>
          <S.Icon src={discover2} />
          <S.Content>Experimente grátis </S.Content>
          <S.Description>
            Acesse o melhor do entretenimento sem limites. A diversão chega
            primeiro aqui.
          </S.Description>
        </S.Grid>
        <S.Grid item xs={12} sm={12} lg={4}>
          <S.Icon src={discover3} />
          <S.Content>Cancele quando quiser </S.Content>
          <S.Description>
            Sem compromisso. Sem multas pelo cancelamento. Você decide.
          </S.Description>
        </S.Grid>
      </Grid>
    </S.Wrapper>
  )
}

export default Discover