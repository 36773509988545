import React from 'react'
import * as S from './style'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom'

const Hero: React.FC = () => {
  const navigate = useNavigate();
  const sm = useMediaQuery('(min-width:600px)');
  return (
    <S.Wrapper>
      {!sm ? <S.MobileBackground
        background={require('../../images/head-banner-mobile.png')}
      /> : <S.HeroImg background={require('../../images/head-banner.png')} />}
      <S.GridContainer container>
        <S.HeroText item md={6}>
          <h1>Você no controle da história.</h1>
          <h2>
            Agora você decide o futuro, o que quer ver em cada etapa desta
            aventura totalmente interativa.
          </h2>
          <S.Button /* href="https://app.viuzz.com.br/cadastrar" */ onClick={() => navigate('/manutencao')}>Experimente 7 dias grátis</S.Button>
        </S.HeroText>
      </S.GridContainer>
    </S.Wrapper>
  )
}

export default Hero