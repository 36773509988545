import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  padding: 28px 5% 16px;
  display: flex;
  padding-bottom: 100px;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.873188) 40.61%, rgba(16, 17, 29, 0) 88.46%);
  z-index: 1;
`

export const Logo = styled.img`
  width: 150px;

  @media (max-width: 600px) {
    width: 100px;
  }
`

export const Button = styled.a`
  background: linear-gradient(90deg, #fb8404 0%, #fe1e14 100%),
    linear-gradient(0deg, #c4c4c4, #c4c4c4);
  border-radius: 20px;
  border: none;
  cursor: pointer;
  color: var(--white-color);
  text-decoration: none;
  font-family: 'Barlow Semi Condensed';
  text-align: center;

  :focus {
    outline: 0;
  }

  &:hover {
    transition: 350ms;
  }
`

export const LoginButton = styled(Button)`
  background: linear-gradient(90deg, #fb8404 0%, #fe1e14 100%),
    linear-gradient(0deg, #c4c4c4, #c4c4c4);
  font-size: 1.7rem;
  padding: 0.5rem 2rem;
  margin-left: auto;
  min-width: 120px;
  height: max-content;
  &:hover {
    background: var(--button-hover-color);
  }

  @media (max-width: 600px) {
    font-size: 2rem;
    min-width: 80px;
    margin-top: 8px;
    padding: 0.5rem 1rem;
  }
`

export const RegisterButton = styled(Button)`
  border: 2px solid #ffffff;
  box-sizing: border-box;
  background: transparent;
  font-size: 1.6rem;
  min-width: 120px;
  padding: calc(0.5rem - 2px) calc(2rem - 2px);
  margin-left: auto;
  height: max-content;
  &:hover {
    background: #444;
  }

  @media (max-width: 600px) {
    margin-top: 8px;
    font-size: 2rem;
    min-width: 80px;
    margin-left: auto;
    padding: calc(0.5rem - 2px) 0.8rem;
  }
`