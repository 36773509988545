import * as S from './style'
import { Grid } from '@mui/material'

const Body: React.FC = () => {
  return (
    <S.BodyWrapper>
      <S.ContentWrapper container>
        <Grid item md={6} sm={7} xs={12}>
          <S.Title>Interatividade na palma da sua mão!</S.Title>
          <S.Subtitle>
            O melhor do entretenimento feito por você através do toque na tela:
            escolha o que quer assistir, qual assunto abordar, qual câmera e o
            rumo da história
          </S.Subtitle>
        </Grid>
        <S.PhoneViuzz item sm={5} xs={12}>
          <img
            src={require('../../images/phone-bg-viuzz.png')}
            alt="Viuzz no celular"
          />
        </S.PhoneViuzz>
      </S.ContentWrapper>
    </S.BodyWrapper>
  )
}

export default Body