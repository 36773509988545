import styled from 'styled-components'
import { Grid } from '@mui/material'

export const Wrapper = styled.div`
  font-family: 'Barlow Semi Condensed';
  font-size: 2rem;
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 400px) {
    font-size: 1.5rem;
  }
`
export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px;
  @media (min-width: 600px) {
    flex-direction: row;
  }
`
export const ContentCnpj = styled.div`
  width: 100%;
  display: block;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px;
`

export const IconsWrapper = styled(Grid)`
  justify-content: flex-start;
  @media (max-width: 600px) {
    justify-content: center;
  }
`

export const LinksWrapper = styled(Grid)`
  justify-content: flex-end;
  && {
    @media (max-width: 600px) {
      justify-content: center;
    }
  }
`

export const CnpjWrapper = styled(Grid)`
  justify-content: center;
`


export const FooterLinks = styled.a`
  font-weight: 300;
  text-decoration: none;
  margin: 10px;
  color: #c4c4c4;

  align-self: center;
  transition: 350ms;

  :hover {
    color: var(--white-color);
  }
`

export const Cnpj = styled.p`
  font-weight: 300;
  text-decoration: none;
  margin: 10px;
  color: #c4c4c4;
  justify-content:center;

  align-self: center;
  transition: 350ms;

  :hover {
    color: var(--white-color);
  }
`

export const LogoWrapper = styled(Grid)`
  order: -1;
  && {
    margin: 15px 0;
  }
  @media (min-width: 600px) {
    order: 0;
  }
`

export const ViuzzMinimalLogo = styled.img`
  margin: auto 0;
  height: 8rem;
  justify-self: center;

  @media (max-width: 600px) {
    height: 6rem;
  }
`
