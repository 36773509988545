import React from 'react'
import * as S from './style'
import logo from '../../images/logo-beta.svg'
import { useNavigate } from 'react-router-dom'

const Appbar: React.FC = () => {
  const navigate = useNavigate();
  return (
    <S.Wrapper>
      <S.Logo src={logo} />
      <S.LoginButton /* href="https://app.viuzz.com.br/entrar" */ onClick={() => navigate('/manutencao')}>
        Entrar
      </S.LoginButton>
    </S.Wrapper>
  )
}

export default Appbar