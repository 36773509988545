import Appbar from './components/Appbar/Appbar';
import Hero from './components/Hero/Hero';
import Discover from './components/Discover/Discover';
import InteractiveSection from './components/InteractiveSection/InteractiveSection';
import Body from './components/Body/Body';
import Highlights from './components/Highlights/Highlights';
import FaqSection from './components/FaqSection/FaqSection';
import Footer from './components/Footer/Footer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Maintenance from './components/Maintenance/Maintenance';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={
          <div className="App">
            <Appbar />
            <Hero />
            <Discover />
            <InteractiveSection />
            <Body />
            <Highlights />
            <FaqSection />
            <Footer />
          </div>
        }
        />
        <Route path='/manutencao' element={<Maintenance />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
