import styled from 'styled-components'
import { Grid as MuiGrid } from '@mui/material'

export const Wrapper = styled.div`
  /* background: var(--secondary-color);   */
  padding: 24px 5% 48px;
`

export const Title = styled.h1`
  color: var(--white-color);
  text-align: center;
  font-size: 4rem;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0.025em;
  margin-bottom: 32px;
`

export const Grid = styled(MuiGrid)`
  display: flex;
  flex-direction: column !important;
`

export const Icon = styled.img`
  margin: 0 auto 16px;
  height: 60px;

  @media (max-width: 960px) {
    height: 40px;
  }
`

export const Content = styled.h1`
  color: var(--white-color);
  font-size: 2rem;
  text-align: center;
  margin: 0px 16px;
`

export const Description = styled.p`
  color: var(--white-color);
  font-size: 1.5rem;
  text-align: center;
  margin: 0px 16px 24px;
`
