import styled from 'styled-components'
import { Dialog } from '@mui/material'

export const Wrapper = styled.div`
  padding-bottom: 24px;
  overflow: hidden;
`

export const OriginalViuzzLogo = styled.img`
  display: flex;
  margin: auto;
  width: 16 rem;
  padding: 40px 0 24px;
`

export const CarouselWrapper = styled.div`
  width: 80%;
  height: 80%;
  margin: auto;

  & > div .slick-slide {
    padding: 0 10px;
  }
`

export const FlexBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: space-around;
  flex-wrap: wrap;
`

export const GridBox = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 24px;
  grid-row-gap: 40px;
  grid-column-gap: 20px;
  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const ThumbnailsV2 = styled.img`
  width: 230px;
  height: 200px;
  border-radius: 20px;
  align-self: center;
  justify-self: center;
  cursor: pointer;
  transition: transform 200ms ease-out, box-shadow 200ms ease;

  :hover {
    transform: scale(1.1, 1.1);
    box-shadow: 10px 5px 5px rgb(0, 0, 0, 0.2);
  }

  @media (max-width: 600px) {
    width: 120px;
    height: 100px;
  }
`

export const Thumbnails = styled.img`
  margin: 24px 0 48px;
  border-radius: 20px;
  cursor: pointer;
`

export const PlayerDialog = styled(Dialog)`
  & > div .MuiDialog-paper {
    background: var(--secondary-color);
    // max-width: 350px;
    @media (max-width: 960px) {
      width: 60vw;
    }
    @media (max-width: 600px) {
      width: 80vw;
    }
  }
`

export const DialogWrapperV2 = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
`

export const DialogWrapper = styled.div`
  display: flex;
  flex-direction: column;

  && {
    width: 90%;
    height: 90%;
    margin: auto;
  }
  h1 {
    font-size: 3rem;
    letter-spacing: 0.04em;

    color: var(--white-color);
  }
  h2 {
    font-size: 1.7rem;
    font-weight: 300;
    letter-spacing: 0.04em;
    padding: 20px 0;

    color: var(--primary-color);
  }
`

export const BannerV2 = styled.img`
  width: 90%;
  margin: 0px auto 16px;
  border-radius: 20px;

  @media (max-width: 960px) {
    width: 90%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`

export const Banner = styled.img`
  width: 80%;
  margin: 20px auto;

  @media (max-width: 960px) {
    width: 90%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`

export const PlayerWrapper = styled.div`
  font-family: Roboto;
  font-weight: normal;
  width: 100%;
  height: 55vh;
  margin: 20px auto;

  @media (max-width: 960px) {
    height: 40vh;
  }
  @media (max-width: 600px) {
    height: 30vh;
  }

  & > div,
  iframe {
    height: 100%;
    width: 100%;
  }
`

export const SmarthPhonePortrait = styled.img`
  width: 100%;
  margin: auto;

  @media (max-width: 600px) {
    width: 50%;
  }
`

export const Button = styled.a`
  background: var(--primary-color);
  box-shadow: 0px 1px 2px 0px var(--secondary-color);
  margin: 16px auto 10px;
  border-radius: 20px;
  cursor: pointer;
  width: 60%;

  padding: 0.5rem 0.8rem;

  :focus {
    outline: 0;
  }

  :hover {
    background: var(--button-hover-color);
    transition: 350ms;
  }

  text-decoration: none;
  text-shadow: 1px 1px 2px #2e2e2e;
  color: var(--white-color);
  font-family: 'Barlow Semi Condensed';
  font-weight: 500;
  font-size: 2rem;
  text-align: center;

  @media (max-width: 600px) {
    width: 60%;
  }
`