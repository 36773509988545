import styled from 'styled-components'

import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'

export const FaqWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const FaqIcon = styled.img`
  position: absolute;
  right: 2%;
  margin-top: 2%;

  @media (max-width: 600px) {
    width: 30%;
  }
`

export const FaqTitle = styled.h1`
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 5rem;
  letter-spacing: 0.025em;
  padding: 50px 0;

  color: var(--white-color);

  @media (max-width: 600px) {
    padding: 20px 0;
    font-size: 3rem;
  }
`
export const FaqContainer = styled.div`
  width: 40%;
  margin-bottom: 100px;
  @media (max-width: 960px) {
    width: 50%;
  }
  @media (max-width: 600px) {
    width: 85%;
  }
`

export const QuestionContainer = styled(Accordion)`
  margin-bottom: 15px;

  &&& {
    border-radius: 15px;
    background: #121419;
  }

  &&& .MuiAccordionSummary-root {
    border-radius: 20px;
    background: #121419;
  }
  && .MuiAccordionSummary-root.Mui-expanded {
    @media (max-width: 600px) {
      min-height: 30px;
      max-height: 30px;
    }
  }
`

export const Question = styled(AccordionSummary)`
  && {
    border-radius: 15px;
    background: #c4c4c4;
  }
  h1 {
    width: 100%;
    text-align: center;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 2rem;
    line-height: 3rem;
    margin-left: 45px;
    color: var(--white-color);

    @media (max-width: 600px) {
      font-size: 1.9rem;
      margin-left: 38px;
    }
  }

  && {
    @media (max-width: 600px) {
      min-height: 30px;
      max-height: 30px;
    }
  }
`

export const Answer = styled(AccordionDetails)`
  background: #121419;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  h1 {
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 2rem;
    line-height: 3rem;
    text-align: left;

    white-space: pre-wrap;

    color: var(--white-color);
  }
`

export const Text = styled.p`
  color: var(--white-color);
  font-size: 1.5rem;
`

export const FreeTrialButton = styled.a`
  background: linear-gradient(90deg, #FB8404 0%, #FE1E14 100%),
    linear-gradient(0deg, #C4C4C4, #C4C4C4);  
  box-shadow: 0px 1px 2px 0px var(--secondary-color);
  margin-top: 8px;
  margin-bottom: 60px;
  border-radius: 20px;
  cursor: pointer;
  width: 400px;
  padding: 1.5rem 0;

  :focus {
    outline: 0;
  }

  :hover {
    background: var(--button-hover-color);
    transition: 350ms;
  }
  /* Seja Viuz */
  text-decoration: none;
  text-shadow: 1px 1px 2px #2e2e2e;
  color: var(--white-color);
  font-family: 'Barlow Semi Condensed';
  font-weight: 500;
  font-size: 2.5rem;
  text-align: center;

  @media (max-width: 600px) {
    width: 60%;
  }
`
