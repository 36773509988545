const highlightedVideos = [
  {
    id: 0,
    title: 'Fã de Carteirinha',
    description:
      'Otariano vai entrevistar convidados que você ama, a cada episódio, e claro, é você quem vai controlar esse bate papo com apenas um toque na tela.',
    thumbnail: require('../images/highlights/highlight-1.jpeg'),
    trailerID: 0,
    banner: require('../images/highlights/highlight-banner-1.png'),
    button: false,
    inviteLink: '',
  },
  {
    id: 1,
    title: 'Alvaro antes do Xaro - A história do Alvxaro',
    description:
      'Se você já adora o Alvxaro hoje, imagina depois que visitar seu passado e conhecer tudo que o nosso digital influencer preferido aprontava? Emoções, confissões, histórias e muita risada.',
    thumbnail: require('../images/highlights/highlight-2.jpeg'),
    trailerID: 0,
    banner: require('../images/highlights/highlight-banner-2.png'),
    button: false,
    inviteLink: '',
  },
  {
    id: 2,
    title: 'Quase um Perrengue',
    description:
      'Se Marcelle Casagrande acha que já fez de tudo nessa vida, a Viuzz a convida para entrar em 10 universos diferentes e mostrar que ainda tem muito perrengue para passar, onde quem dirige a história, através do toque na tela, é você.',
    thumbnail: require('../images/highlights/highlight-3.jpeg'),
    trailerID: 0,
    banner: require('../images/highlights/highlight-banner-3.png'),
    button: false,
    inviteLink: '',
  },
  {
    id: 3,
    title: 'Um Crush em Milagres',
    description:
      'Durante uma semana, Rico Melquiades e Ney Lima ficarão confinados em uma mansão com 8 pretendentes. Cada CRUSH tentará conquistar o coração dos nossos influencers. Será que rola um amor verdadeiro?',
    thumbnail: require('../images/highlights/highlight-4.jpeg'),
    trailerID: 0,
    banner: require('../images/highlights/highlight-banner-4.png'),
    button: false,
    inviteLink: '',
  },
]

export default highlightedVideos
