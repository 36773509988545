import { useMediaQuery } from '@mui/material';
import * as S from './style';
import { Grid } from '@mui/material';
import logo from '../../images/logo-beta.svg'
import appMock from '../../images/app-screenshot.png'
import { useNavigate } from 'react-router-dom'

const InteractiveSection: React.FC = () => {
  const navigate = useNavigate();
  const sm = useMediaQuery('(min-width:600px)');

  const registerButton = (
  <S.Button
    /* href="https://app.viuzz.com.br/cadastrar" */
    onClick={() => navigate('/manutencao')}
  >
    Experimente 7 dias grátis
  </S.Button>);

if (!sm) {
  return (
    <S.Wrapper>
      <S.Logo src={logo} />
      <S.Text>Baixe o aplicativo</S.Text>
      <S.SmartPhoneContainer>
        {/* <S.Video src="https://player.vimeo.com/video/493346850" width="100%" height="650" allow="autoplay; fullscreen"></S.Video> */}
        <S.Image src={appMock} />
        <S.SmartPhonePortrait
          src={require('../../images/smartphone-portrait.png')}
        />
      </S.SmartPhoneContainer>
      {registerButton}
    </S.Wrapper>
  )
}

return (
  <S.Wrapper>
    <Grid container alignContent="center">
      <Grid item md={6}>
        <S.LeftPanel>
          <S.SmartPhoneContainer>
            {/* <S.Video src="https://player.vimeo.com/video/493346850" width="100%" height="650" allow="autoplay; fullscreen"></S.Video> */}
            <S.Image src={appMock} />
            <S.SmartPhonePortrait
              src={require('../../images/smartphone-portrait.png')}
            />
          </S.SmartPhoneContainer>
        </S.LeftPanel>
      </Grid>
      <Grid item md={6}>
        <S.RightPanel>
          <S.Logo src={logo} />
          <S.Text>Baixe o aplicativo</S.Text>
          {registerButton}
        </S.RightPanel>
      </Grid>
    </Grid>
  </S.Wrapper>
)
}

export default InteractiveSection