import styled, { css } from 'styled-components'
import { Grid } from '@mui/material'

type WrapperProps = {
  background: string
}

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  min-height: 110vh;
`

export const HeroImg = styled.main<WrapperProps>`
  ${({ background }) => css`
    display: flex;
    opacity: 0.7;
    flex-direction: column;
    justify-content: center;
    min-height: 110vh;
    background-image: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0.873188) 15.00%, rgba(16, 17, 29, 0) 30.46%),
      url(${background});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
  `}
`

export const MobileBackground = styled.div<WrapperProps>`
  ${({ background }) => css`
    background-image: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0.873188) 15.00%, rgba(16, 17, 29, 0) 30.46%),
      url(${background});
    display: flex;
    opacity: 0.7;
    flex-direction: column;
    min-height: 600px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
  `}
`

export const Button = styled.a`
  box-shadow: 0px 1px 2px 0px #000000;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  text-shadow: 1px 1px 2px #2e2e2e;
  color: var(--white-color);
  text-decoration: none;
  font-family: 'Barlow Semi Condensed';
  text-align: center;
  background: linear-gradient(90deg, #fb8404 0%, #fe1e14 100%), #c4c4c4;
  font-size: 3rem;
  padding: 0.5rem 5rem;

  :focus {
    outline: 0;
  }

  :hover {
    background: var(--button-hover-color);
    transition: 300ms;
  }
`

export const Logo = styled(Grid)`
  img {
    @media (max-width: 960px) {
      width: 130px;
    }
    @media (max-width: 600px) {
      width: 220px;
    }
  }
`

export const GridContainer = styled(Grid)`
  position: absolute;
`

export const HeroText = styled(Grid)`
  padding: 0 5%;
  text-align: left;
  h1 {
    color: var(--white-color);
    margin: 15px 0;

    font-size: 5rem;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.025em;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0.025em;
    @media (max-width: 600px) {
      font-size: 4rem;
      margin: 8px 0;
      line-height: 40px;
    }
  }

  h2 {
    color: var(--white-color);

    font-size: 2rem;
    line-height: 4rem;
    letter-spacing: 0.025em;

    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: 0.025em;

    margin: 15px 0;

    @media (max-width: 600px) {
      font-size: 2rem;
      margin: 8px 0;
      line-height: 25px;
    }
  }
`
