import React from 'react'
import * as S from './style'
import { FiTwitter, FiInstagram } from 'react-icons/fi'
import { SiTiktok } from 'react-icons/si'
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa'
import logoMinimal from '../../images/logo-beta-minimal.svg'

const Footer: React.FC = () => {
  return (
    <>
    
    <S.Wrapper>
      <S.Content>
        <S.IconsWrapper container item sm={5} xs={12} alignItems="flex-end">
          <S.FooterLinks
            href="https://www.linkedin.com/company/viuzz/"
            target="_blank"
          >
            <FaLinkedinIn size={'3rem'} />
          </S.FooterLinks>
          <S.FooterLinks href="https://twitter.com/zedaviuzz" target="_blank">
            <FiTwitter size={'3rem'} />
          </S.FooterLinks>
          <S.FooterLinks
            href="https://www.instagram.com/viuz.z/"
            target="_blank"
          >
            <FiInstagram size={'3rem'} />
          </S.FooterLinks>
          <S.FooterLinks
            href="https://www.facebook.com/viuzzoficial"
            target="_blank"
          >
            <FaFacebookF size={'3rem'} />
          </S.FooterLinks>
          <S.FooterLinks href="https://vm.tiktok.com/ZSaPx3QW/" target="_blank">
            <SiTiktok size={'3rem'} />
          </S.FooterLinks>
        </S.IconsWrapper>
        <S.LogoWrapper
          container
          item
          sm={2}
          xs={12}
          justifyContent="center"
          alignItems="flex-end"
        >
          <S.ViuzzMinimalLogo
            src={logoMinimal}
            alt="Viuzz"
          />
        </S.LogoWrapper>
        <S.LinksWrapper
          container
          item
          sm={5}
          xs={12}
          alignItems="flex-end"
        >
          <S.FooterLinks href="https://app.viuzz.com.br/termos-de-uso">Termos de uso</S.FooterLinks>
          <S.FooterLinks href="https://app.viuzz.com.br/termos-de-privacidade">Privacidade</S.FooterLinks>
          <S.FooterLinks href="https://app.viuzz.com.br/ajuda">
            Ajuda
          </S.FooterLinks>
          <S.FooterLinks href="#">Viuzz</S.FooterLinks>
        </S.LinksWrapper>
        
      </S.Content>
    </S.Wrapper>
    <S.Wrapper>
      <S.ContentCnpj>
        <S.CnpjWrapper
          container
          item
          sm={12}
          xs={12}
          alignItems="center"
        >
          <S.Cnpj>Conteúdo licenciado por Viraliza Entretenimento - 29.121.296/0002-61</S.Cnpj>
        </S.CnpjWrapper>
      </S.ContentCnpj>
    </S.Wrapper>
    </>
  )
}

export default Footer