import styled from "styled-components";

export const Wrapper = styled.div`
  /* background-color: var(--secondary-color); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;

  @media (min-width: 960px) {
    min-height: 50vh;
  }
`

export const LeftPanel = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 20%;
  padding-right: 5%;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 20px;
`

export const SmartPhoneContainer = styled.div`
  width: 50%;
  margin: auto;
  position: relative;
  background-color: black;
  border-radius: 20px;
  padding-top: 90.25%;

  @media (max-width: 960px) {
    margin-top: 40px;
    width: 50%;
  }
`
export const Video = styled.iframe`
  margin-top: 10px;
  margin-bottom: 10px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 96%;
`

export const Image = styled.img`
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 93%;
  height: 96%;
`

export const SmartPhonePortrait = styled.img`
  position: absolute;
  top: 0;
  bottom: -3%;
  left: -5%;
  right: 0;
  width: 110%;
  height: 110%;
  pointer-events: none;
  margin: auto;
`
export const SmartControls = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 60%;
  pointer-events: none;
  margin: auto;
`

export const RightPanel = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding-left: 0px;
  padding-right: 20%;
  gap: 12px;
`

export const Logo = styled.img`
  display: flex;
  width: 200px;

  @media (max-width: 960px) {
    width: 150px;
    margin: 64px auto 0px;
  }
`

export const Text = styled.p`
  font-size: 4rem;
  color: var(--white-color);
  @media (max-width: 960px) {
    margin: 16px;
    text-align: center;
  }
`

export const Button = styled.a`
  background: var(--primary-color);
  border-radius: 20px;
  border: none;
  cursor: pointer;
  color: var(--white-color);
  text-decoration: none;
  font-family: 'Barlow Semi Condensed';
  text-align: center;
  width: max-content;
  padding: 1rem 6rem;
  font-size: 3rem;
  font-weight: 400;
  margin-top: 16px;

  &:focus {
    outline: 0;
  }

  &:hover {
    background: var(--button-hover-color);
    transition: 350ms;
  }

  @media (max-width: 960px) {
    width: 50%;
    font-size: 3rem;
    margin: 40px auto 64px;
  }

  @media (max-width: 600px) {
    font-size: 2rem;
  }
`
