import React from 'react'
import questions from '../../data/questions'
import * as S from './style'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom'

const FaqSection = () => {
  const navigate = useNavigate();
  return (
    <S.FaqWrapper>
      <S.FaqTitle>Perguntas frequentes</S.FaqTitle>
      <S.FaqContainer>
        {questions.map((faq, i) => (
          <S.QuestionContainer key={i}>
            <S.Question
              expandIcon={
                <KeyboardArrowDownIcon
                  fontSize={'large'}
                  style={{ color: '#fff' }}
                />
              }
            >
              <h1>{faq.question}</h1>
            </S.Question>
            <S.Answer>
              <h1>{faq.answer}</h1>
            </S.Answer>
          </S.QuestionContainer>
        ))}
      </S.FaqContainer>
      <S.Text>Pronto para assistir? Venha aproveitar seus 7 dias grátis.</S.Text>
      <S.FreeTrialButton /* href="https://nft.viuzz.com.br" */ onClick={() => navigate('/manutencao')}>Experimente 7 dias grátis</S.FreeTrialButton>
    </S.FaqWrapper>
  )
}

export default FaqSection