import styled from 'styled-components'
import { Grid, CircularProgress } from '@mui/material'

export const BodyWrapper = styled.div`
  padding: 0 10%;
  font-family: 'Exo 2';

  @media (max-width: 600px) {
    padding: 0 8%;
  }
`

export const ContentWrapper = styled(Grid)`
  padding: 70px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const PhoneViuzz = styled(Grid)`
  img {
    max-width: 100%;
  }

  && {
    @media (max-width: 600px) {
      max-width: 70%;
      margin: auto;
    }
  }
`

export const Title = styled.h1`
  font-size: 4rem;
  font-weight: 500;
  margin-bottom: 24px;
  color: var(--white-color);
`

export const Subtitle = styled.p`
  font-size: 3rem;
  font-weight: 400;
  margin-bottom: 48px;
  font-style: normal;
  line-height: 32px;
  letter-spacing: 0.025em;

  color: var(--white-color);
`

export const NotebookTabletIcons = styled(Grid)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 40px 0;

    @media (max-width: 600px) {
      justify-content: left;
      img {
        margin-right: 50px;
        :first-child {
          height: 70px;
        }
        :last-child {
          height: 60px;
        }
      }
    }
  }
`

export const IconsText = styled(Grid)`
  && {
    margin-bottom: 70px;
  }
  h3 {
    font-size: 3rem;
    font-weight: 500;
    line-height: 3.6rem;
    letter-spacing: 0.025em;
    color: var(--secondary-color);

    small {
      font-weight: 300;
      font-size: 1.8rem;
      line-height: 2.2rem;
      letter-spacing: 0.025em;
      padding-top: 12px;
      color: #878787;

      span {
        font-weight: 900;
        color: var(--primary-color);
      }
    }
  }
`

export const Loading = styled(CircularProgress)``

export const PlanCard = styled.div`
  box-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  display: flex;
  float: right;
  flex-direction: column;
  justify-content: space-between;
  width: max-content;
  && {
    margin-right: 8%;
  }

  min-height: 400px;
  @media (max-width: 600px) {
    min-height: 250px;
    && {
      margin: 0 10% 50px;
    }
  }
`

export const PlanCardHeader = styled.div`
  background: var(--primary-color);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  padding: 0px 16px;
  display: flex;
  justify-content: space-between;
`

export const PlanTitle = styled.h1`
  font-weight: 500;
  font-size: 2.6rem;
  line-height: 5.5rem;
  letter-spacing: 0.025em;
  white-space: pre-wrap;
  color: #673703;
  align-self: center;
  margin-right: 20px;

  span {
    font-weight: 900;
  }
`

export const PlanValue = styled.h1`
  color: #673703;
  font-size: 1.4rem;
  align-self: center;

  span {
    color: var(--white-color);
    font-size: 2.6rem;
    font-weight: bold;
    small {
      font-size: 1.4rem;
    }
  }
`

export const PlanCardBody = styled.div`
  p {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 1.7rem;
    letter-spacing: 0.04em;
    width: 100%;
    padding: 0 15%;
    margin: 20px 0;

    color: #181717;
  }
`

export const FreeTrialButton = styled.a`
  background: var(--primary-color);
  box-shadow: 0px 1px 2px 0px var(--secondary-color);
  border-radius: 20px;
  cursor: pointer;
  margin: 0 auto 20px;
  padding: 1.5rem 1.5rem;

  :focus {
    outline: 0;
  }

  :hover {
    background: var(--button-hover-color);
    transition: 350ms;
  }
  /* Seja Viuzz */
  text-decoration: none;
  text-shadow: 1px 1px 2px #2e2e2e;
  color: var(--white-color);
  font-family: 'Barlow Semi Condensed';
  font-weight: 500;
  font-size: 2.5rem;
  text-align: center;
`

export const LastWords = styled.div`
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 2.8rem;
  letter-spacing: 0.04em;
  color: #47484f;

  h1 {
    margin: 0px 0px 20px;
    color: #47484f;

    span {
      color: #fb8404;
    }
  }

  p {
    font-weight: normal;
    font-size: 4rem;
  }
`
