import React from 'react';
import * as S from './style'
import Slider from "react-slick";
import highlightedVideos from '../../data/highlighted-videos'
import Vimeo from '@u-wave/react-vimeo'
import originalLogo from '../../images/original-viuzz-logo.svg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

type Video = {
  id: number
  title: string
  description: string
  thumbnail: string
  trailerID: number
  banner: string
  button: boolean
  inviteLink: string
}[]

type VideoDialogProps = {
  open: boolean
  handleClose: () => void
  highlightedVideo: Video
  videoID: number
}

const VideoDialog: React.FC<VideoDialogProps> = ({
  open,
  handleClose,
  highlightedVideo,
  videoID,
}) => {
  return (
    <S.PlayerDialog open={open} onClose={handleClose} scroll={'body'}>
      <S.DialogWrapper>
        {highlightedVideo[videoID].trailerID ? (
          <S.PlayerWrapper>
            <Vimeo
              video={highlightedVideo[videoID].trailerID}
              autoplay
              volume={0}
            />
          </S.PlayerWrapper>
        ) : (
          <S.Banner
            src={highlightedVideo[videoID].banner}
            alt={highlightedVideo[videoID].title}
          />
        )}
        <h1>{highlightedVideo[videoID].title}</h1>
        <h2>{highlightedVideo[videoID].description}</h2>
        {highlightedVideo[videoID].button && (
          <S.Button href={highlightedVideo[videoID].inviteLink}>
            Assinar
          </S.Button>
        )}
      </S.DialogWrapper>
    </S.PlayerDialog>
  )
}

const Carousel: React.FC = () => {
  const [open, setOpen] = React.useState(false)
  const [videoID, setVideoID] = React.useState<number>(0)

  const handleOpen = (videoID: number) => {
    setOpen(true)
    setVideoID(videoID)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const settings = {
    draggable: false,
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }


  return (
    <>
      <VideoDialog
        open={open}
        handleClose={handleClose}
        highlightedVideo={highlightedVideos}
        videoID={videoID}
      />
      <Slider {...settings}>
        {highlightedVideos.map((video) => (
          <S.Thumbnails
            src={video.thumbnail}
            alt={video.title}
            key={video.id}
            onClick={() => handleOpen(video.id)}
          />
        ))}
      </Slider>
    </>
  )
}

const Highlights: React.FC = () => {
  return (
    <S.Wrapper>
      <S.OriginalViuzzLogo
        src={originalLogo}
        alt="Original Viuzz"
      />
      <S.CarouselWrapper>
        <Carousel />
      </S.CarouselWrapper>
    </S.Wrapper>
  )
}

export default Highlights